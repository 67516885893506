<template>
    <div class="task-create">
        <teleport v-if="componentMounted" to="#header-nav">
            <CBreadcrumbItem active>
                Tasks
            </CBreadcrumbItem>
        </teleport>

        <div class="task-create__steps">
            <CNStep v-if="activeStep !== null" :steps="steps" :current-step="activeStep" @click="onStepClick"/>
        </div>
        <RouterView @last-available-step="onStep"/>
    </div>
</template>

<script>
import CNStep from "@/components/ui/CNStep/CNStep.vue";

export default {
    name: "index",
    components: {CNStep},
    data() {
        return {
            activeStep: null,
            componentMounted: false,
            steps: [
                {
                    name: '1. Assigned users',
                    icon: 'cilSolidCheck',
                    disabled: false,
                    route: {
                        name: 'ProjectScopesTasksAssign',
                        params: {...this.$route.params},
                    },
                },
                {
                    name: '2. reView Contract Package',
                    icon: 'cilSolidUser',
                    disabled: false,
                    route: {
                        name: 'ProjectScopesTaskReview'
                    },
                },
                {
                    name: '3. review Merge Fields',
                    icon: 'cilExclamationLabel',
                    disabled: false,
                    route: {
                        name: 'ProjectScopesTaskMergeFields',
                    },
                },
                {
                    name: '4. Confirm & Send',
                    icon: 'cilSendPlane',
                    disabled: false,
                    route: {
                        name: 'ProjectScopesTaskConfirm',
                    },
                }
            ],
        }
    },
    methods: {
        onStep(step) {
            this.activeStep = step - 1;
        },
        onStepClick(key, item) {
            if (key > this.step) return false
            this.$router.push(item.route)
        }
    },
    mounted() {
        this.componentMounted = true
    }
}
</script>

<style lang="scss">
.task-create {

    &__steps {
        background: white;
        padding: 28px 24px 20px 24px;
        border-radius: 8px 8px 0 0;
    }

    & .stepper {
        justify-content: flex-start;
        font-family: "Segoe UI", sans-serif;
    }

    & .step {
        width: auto;
        padding: 0 30px;

        &::before {
            border-radius: 0;
        }
    }
}
</style>
